import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const BoardMeeting = () => {
  const headingbanner = {
    title: `Boardmeetingz`,
    content: `An Online Meeting Platform`,
  };
  const data = {
    images: ["board-meeting.webp", "board-meeting-2.webp"],
    firstAlt: "Dashboard to check the task list of board members",
    firstTitle: "Dashboard",
    secondAlt: "UI Screen to check the approvals",
    secondTitle: "Approvals",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["SaaS"],
      },
      {
        title: "What we did",
        text: [
          "UX Consultation",
          "UI UX Designing",
          "UI Development",
        ],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `Our main challenge was to develop an intuitive and dynamic meeting platform that can effectively replace traditional tools by offering a seamless user experience. `,
          `We had to incorporate guided actions in the platform to streamline processes, enable efficient document management capabilities, facilitate live sharing and annotations, as well as provide essential features for meeting scheduling and video conferencing, all in one comprehensive solution.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `Our primary goal was to develop a secure and paperless board meeting solution that aligns with our intended positioning.`,
          `We aimed to establish a trusted and reliable platform that prioritizes security and privacy. Additionally, we strived to make our solution adaptable to multiple age groups, ensuring that all users would find it user-friendly and accessible.`,
          `We kept the cross-platform design minimal to provide a consistent and seamless user experience across devices. `,
          `Finally, we focused on maintaining dynamic organizational governance, allowing for easy collaboration, decision-making, and accountability within boards and committees.`,
        ],
      },
    ],
    image: ["board-meeting-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: "Dashboard",
    para: [
      `Once the user logs in to the platform, they see this dashboard to check the meeting schedule and actions to be taken for a specific discussion. `,
    ],
    content: {
      imageAlt: 'UI Dashboard to keep track on tasks',
      imageTitle: 'Dashboard',
      image: ["board-meeting-projectone.webp"],
      text: `We created this interface to increase board members’ productivity.`,
    },
    content2: {
      imageAlt: 'Choose and start discussions',
      imageTitle: 'Discussions',
      image: ["board-meeting-projectone-2.webp"],
      text: "Board Members can also discuss specific agenda, start online discussions and vote online to come to a conclusion to ensure smooth and informed decision-making. ",
    },
  };

  const projectData2 = {
    title: `Video Conference`,
    para: [
      `We have integrated a video chat option in the platform for the members to discuss business challenges and opportunities for improved user experience. `,
    ],
    content: {
      image: ["board-meeting-projecttwo.webp"],
      imageAlt: 'UI Screen to conduct virtual conference',
      imageTitle: 'Video Conferencing',
    },
  };

  const projectData3 = {
    title: "Login",
    para: [
      `We also created a login screen for the members to ensure security, privacy, control, etc. and to offer a personalised platform experience. `,
    ],
    content: {
      image: [
        "board-meeting-projectthree.webp",
        "board-meeting-projectthree-2.webp",
        "board-meeting-projectthree-3.webp",
      ],
      firstAlt: 'Login Screen',
      firstTitle: 'Login Screen',
      secondAlt: 'Menu Option for conducting board meetings',
      secondTitle: 'Menu',
      thirdAlt: 'UI Screen to check approvals',
      thirdTitle: 'Approvals',
    },
  };

  const conclusionData = [
    {
      para: [
        `In conclusion, we successfully developed an intuitive and dynamic online meeting platform that effectively replaces traditional tools for conducting company board operations. `,
        `Our comprehensive solution incorporates guided actions, efficient document management capabilities, live sharing and annotations, as well as essential features for meeting scheduling and video conferencing. `,
        `With a primary focus on security, privacy, adaptability, and a seamless user experience, our platform provides a trusted and reliable solution for board meetings, facilitating easy collaboration, decision-making, and accountability within organizations.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={34} />
      </div>
    </Layout>
  );
};

export default BoardMeeting;

export const Head = () => (
  <Seo title="BoardMeetingz - Octet Design Studio" description="A Kenya based paperless board meeting management system to whom we offered UX Consultation, UI UX Designing and iOS Application Development." />
)